import React from "react";
import NavBarComponent from "../components/nav/nav";
import LegalPolicyComponent from "../components/legalPolicy/legalPolicyComponent";
import FooterCompoent from "../components/footer/Footer.tsx";

export default function LegalPolicy() {
  return (
    <div style={{ height: "100%" }}>
      <NavBarComponent />
      <LegalPolicyComponent />
      <FooterCompoent />
    </div>
  );
}
