import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "./LegalPolicy.module.css";

const general = [
  {
    name: "Terms and Conditions",
    link: "/terms-and-conditions",
    open: "https://5cd20769-ee17-4b8d-9011-ca225873a339.filesusr.com/ugd/ffe100_2c3a39ccb12c4887923d428accdb72fb.pdf",
  },
  {
    name: "Estu Privacy Policy",
    link: "/privacy-policy",
    open: "https://5cd20769-ee17-4b8d-9011-ca225873a339.filesusr.com/ugd/ffe100_9af7c0b9f61d40218a55f107669fe6e9.pdf",
  },
  {
    name: "Electronic Consent",
    link: "/electronic-consent",
    open: "https://5cd20769-ee17-4b8d-9011-ca225873a339.filesusr.com/ugd/ffe100_b44b3d9c498e4e83afd5065cad1b3466.pdf",
  },
];

const banking = [
  {
    name: "Estu Fee Schedule",
    link: "/estu-fee-schedule",
    // open: "https://5cd20769-ee17-4b8d-9011-ca225873a339.filesusr.com/ugd/ffe100_ec730db4b92249e5977e6ab253af50f2.pdf",
    // open: "https://d34gt69eepjr0b.cloudfront.net/fee_schedule.pdf",
    open: "https://d34gt69eepjr0b.cloudfront.net/feeSchedulev4.pdf",
  },
  {
    name: "Customer Account and Cardholder Agreement",
    link: "/customer-account-and-cardholder-agreement",
    // open: "https://d34gt69eepjr0b.cloudfront.net/customer_acc.pdf",
    open: "https://d34gt69eepjr0b.cloudfront.net/customer_accv2.pdf",
  },
];
const estuAds = [
  {
    name: "EstuAds Privacy Policy",
    link: "/estu-ads-privacy-policy",
    open: "https://d34gt69eepjr0b.cloudfront.net/estuads-privacypolicy.pdf",
  },
  {
    name: "Terms and Conditions",
    link: "/estu-ads-terms-and-conditions",
    open: "https://d34gt69eepjr0b.cloudfront.net/estuads-termsandconditions.pdf",
  },
];

const png = [
  {
    name: "Estu Ticket Disclosure",
    link: "/png-estu-ticker-disclosure",
    open: "https://d34gt69eepjr0b.cloudfront.net/ticket-disclosure-v1.pdf",
  },
  {
    name: "Estu Referral Program Disclosure",
    link: "/png-estu-referral-program-disclosure",
    open: "https://d34gt69eepjr0b.cloudfront.net/referralProgramv5.pdf",
  },
  {
    name: "Estu Waive Monthly Fee",
    link: "/png-estu-waiver-monthly-fee",
    open: "https://d34gt69eepjr0b.cloudfront.net/monthly-waiver-v2.pdf",
  },
  {
    name: "Estu Exclusive Deals Disclosure",
    link: "/png-estu-exclusive-deals-disclosure",
    open: "https://d34gt69eepjr0b.cloudfront.net/exclusive-deals-disclosurev1.pdf",
  },
];

export default function LegalPolicyComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Click = (data) => {
    navigate(`${data.link}`);
  };

  return (
    <div className={style.container}>
      <div className={style.top}>
        <div className={style.title}>
          <p>CHECK OUT ESTU'S LEGAL TERMS AND CONDITIONS</p>
        </div>
        <div className={style.des}>
          <p>
            We comply with national laws to offer the best solution for your
            financial life
          </p>
        </div>
      </div>
      <div className={style.body}>
        <div className={style.general}>
          <div className={style.title}>
            <p>GENERAL</p>
          </div>
          {general.map((data) => (
            <button
              onClick={(_) => window.open(data.open)}
              className={style.linkButton}
            >
              {data.name}
            </button>
          ))}
        </div>
        <div className={style.general}>
          <div className={style.title}>
            <p>BANKING</p>
          </div>
          {banking.map((data) =>
            data.open ? (
              <button
                onClick={(_) => window.open(data.open)}
                className={style.linkButton}
              >
                {data.name}
              </button>
            ) : (
              <button onClick={(_) => Click(data)} className={style.linkButton}>
                {data.name}
              </button>
            )
          )}
        </div>
        <div className={style.general}>
          <div className={style.title}>
            <p>ESTU ADS</p>
          </div>
          {estuAds.map((data) =>
            data.open ? (
              <button
                onClick={(_) => window.open(data.open)}
                className={style.linkButton}
              >
                {data.name}
              </button>
            ) : (
              <button onClick={(_) => Click(data)} className={style.linkButton}>
                {data.name}
              </button>
            )
          )}
        </div>
        <div className={style.general}>
          <div className={style.title}>
            <p>PROMOTIONS & GIVEAWAYS</p>
          </div>
          {png.map((data) =>
            data.open ? (
              <button
                onClick={(_) => window.open(data.open)}
                className={style.linkButton}
              >
                {data.name}
              </button>
            ) : (
              <button onClick={(_) => Click(data)} className={style.linkButton}>
                {data.name}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
}
