import React, { useState } from "react";
import style from "./footer.module.css";
import axios from "axios";
import Logo from "../../../assets/logo.png";
import { RightArrow } from "../../../assets/logo/logos";
import ContactComponent from "../../../pages/admaster/admaster/contact/contact";
import { useNavigate } from "react-router";
import logo from "../../../assets/logo.png";
import {
  EmailIcon,
  InstagramIcon,
  LegalIcon,
  LinkedInIcon,
  TiktokIcon,
  TwitterXIcon,
  YoutubeIcon,
} from "../../../assets/svgs";
export default function FooterCompoent() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState("Email sent successfully!");
  const [emailFlag, setEmailFlag] = useState(false);

  const ValidateEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };

  const showEmailSentText = async () => {
    if (email.length > 0) {
      const bool = ValidateEmail();
      console.log(bool);
      if (bool) {
        await axios.post("https://api.estubank.com/api/v1/early-access", {
          email: email,
        });
        setEmailSent("Email sent successfully!");
        setEmailFlag(true);
        setTimeout(() => {
          setEmailFlag(false);
        }, 2000);
      } else {
        setEmailSent("You have entered an invalid email address!");
        setEmailFlag(true);
        setTimeout(() => {
          setEmailFlag(false);
        }, 2000);
      }
    } else {
      setEmailSent("Please Enter an  email address!");
      setEmailFlag(true);
      setTimeout(() => {
        setEmailFlag(false);
      }, 2000);
    }
  };

  const footerConfig = [
    {
      text: "Estu is not a bank. Estu is a financial technology company. The Estu Checking Account is provided by Evolve Bank & Trust, Member FDIC. The Estu Visa®️ Debit Card is issued by Evolve Bank & Trust, Member FDIC, pursuant to a license from Visa U.S.A. Inc. Evolve deposits are fully protected up to the standard deposit insurance amount by the FDIC which is $250,000 per depositor, per insured bank, for each account ownership category. FDIC insurance protects against the failure of an FDIC-insured depository institution.",
    },
    {
      text: "Discounts and Rewards are not offered by Evolve Bank & Trust and are instead offered and managed by Estu.",
    },
    {
      text: "Events are not offered by Evolve Bank & Trust and are instead offered and managed by Estu.",
    },
  ];
  // return (
  //   <div className={style.container}>
  //     {/* <img src={Logo} alt="logo" /> */}
  //     {/* <div className={style.title}>Partner with estu</div> */}
  //     {/* <div className={style.emailContainer}>
  //       <input
  //         onChange={(e) => setEmail(e.target.value)}
  //         type="email"
  //         placeholder="Share your Email address"
  //       />
  //       <div onClick={showEmailSentText} className={style.emailButton}>
  //         Submit
  //         //  <RightArrow />
  //       </div>
  //     </div> */}
  //     <div className={style.footerLinksCont}>
  //       <div className={style.opt}>
  //         <img
  //           src={Logo}
  //           alt="logo"
  //           // style={{ width: "2rem", height: "2rem" }}
  //           className="cursor-pointer"
  //           onClick={() => {
  //             window.scrollTo({ top: 0, behavior: "smooth" });
  //           }}
  //         />{" "}
  //         <span className={style.footerLinksText}>Student Life. Balanced.</span>
  //       </div>
  //       <div className={style.outlinks}>
  //         {" "}
  //         {/* <div
  //           className={style.footerLinksText}
  //           // onClick={() => navigate("/legal")}
  //           onClick={() => {
  //             window.location.reload();
  //             window.scrollTo({ top: 0, behavior: "smooth" });
  //           }}
  //         >
  //           Legal
  //         </div> */}
  //         {/* <div
  // 					className={style.footerLinksText}
  // 					onClick={() => navigate('/privacy-policy')}>
  // 					Privacy Policy
  // 				</div>
  // 				<div
  // 					className={style.footerLinksText}
  // 					onClick={() => navigate('/terms-and-conditions')}>
  // 					Terms & Condition
  // 				</div> */}
  //       </div>

  //       <div
  //         style={{
  //           fontWight: 500,
  //           fontFamily: "Montserrat",
  //           marginBottom: "20px",
  //         }}
  //       >
  //         Estu is not a bank. Estu is a financial technology company. Banking
  //         services provided by Evolve Bank & Trust; Member FDIC
  //       </div>
  //     </div>

  //     <div
  //       className="email_sent email_sent1"
  //       style={emailFlag ? { visibility: "visible" } : { visibility: "hidden" }}
  //     >
  //       {emailSent}
  //     </div>
  //     <div className={style.sticky}>
  //       <div className={style.powered}>
  //         Powered by <span style={{ fontWeight: "bold" }}>Estu</span>
  //       </div>
  //       <div className={style.company}>
  //         <span style={{ fontWeight: "bold" }}>
  //           © 2024 Estu, All rights reserved
  //         </span>
  //       </div>
  //     </div>
  //   </div>
  // );
  const handleOpenLink = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div className={style.container}>
      <div className={style.contentDiv}>
        {/* left div start*/}
        <div
          className={style.leftDiv}
          // className="w-full md:w-fit h-full flex flex-col justify-between items-start gap-4"
        >
          <img
            loading="lazy"
            src={logo}
            alt="logo"
            className={style.logo}
            onClick={() => window.open("https://estulife.com", "_blank")}
          />

          <div
            className={style.contactInfo}
            onClick={() =>
              handleOpenLink(
                "https://mail.google.com/mail/?view=cm&to=contact@estulife.com"
              )
            }
          >
            <div className={style.icon}>
              <EmailIcon />
            </div>
            <p className={style.contactText}>contact@estulife.com</p>
          </div>
          <div
            className={style.contactInfo}
            onClick={() => handleOpenLink("https://legal.estulife.com")}
          >
            <div className={style.icon}>
              <LegalIcon />
            </div>
            <p className={style.contactText}>Legal</p>
          </div>
        </div>
        {/* left div end */}
        {/* center div */}
        <div className={style.centerDiv}>
          <p className={style.socialTitle}>Connect with us on Social media</p>
          <div className={style.socialIcons}>
            <YoutubeIcon
              className={style.icon}
              onClick={() =>
                handleOpenLink(
                  "https://www.youtube.com/channel/UCYi4BjVzMHwfRS0cvRq4EVQ"
                )
              }
            />
            <LinkedInIcon
              className={style.icon}
              onClick={() =>
                handleOpenLink("https://www.linkedin.com/company/estulife/")
              }
            />
            <TiktokIcon
              className={style.icon}
              onClick={() => handleOpenLink("https://www.tiktok.com/@estulife")}
            />
            <TwitterXIcon
              className={style.icon}
              onClick={() => handleOpenLink("https://x.com/estulife")}
            />
            <InstagramIcon
              className={style.icon}
              onClick={() =>
                handleOpenLink("https://www.instagram.com/estulife/")
              }
            />
          </div>
        </div>
        {/* center div end */}
        {/* right div start */}
        <div className={style.rightDiv}>
          <p className={style.socialTitle}>Disclaimers</p>

          <p className={style.disclaimerText}>
            <sup>1</sup>Estu is not a bank. Estu is a financial technology
            company. The Estu Checking Account is provided by Evolve Bank &
            Trust, Member FDIC. The Estu Visa®️ Debit Card is issued by Evolve
            Bank & Trust, Member FDIC, pursuant to a license from Visa U.S.A.
            Inc. Evolve deposits are fully protected up to the standard deposit
            insurance amount by the FDIC which is $250,000 per depositor, per
            insured bank, for each account ownership category. FDIC insurance
            protects against the failure of an FDIC-insured depository
            institution.
          </p>
          <p className={style.disclaimerText}>
            <sup>2</sup>Discounts and Rewards are not offered by Evolve Bank &
            Trust and are instead offered and managed by Estu.
          </p>
          <p className={style.disclaimerText}>
            <sup>3</sup>Events are not offered by Evolve Bank & Trust and are
            instead offered and managed by Estu.
          </p>
        </div>
      </div>
      <p className={style.footerText}>
        &copy;&nbsp;2024 Estu, All rights reserved
      </p>
      {/* right div end */}
    </div>
  );
}
